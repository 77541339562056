<template>
    <b-card class="blog-edit-wrapper">
      <!-- form -->
      <div class="text-center">
        <h4 class="mt-5">
            Accreditations Page
          <feather-icon icon="Edit2Icon" />
        </h4>
      </div>
      <validation-observer ref="AccerditedPageFormvalidate">
        <b-form
          class="mt-2"
          style="width: 100%"
          @submit.prevent="save"
        >
          <b-row>
            <b-col cols="12">
              <b-form-group
  
                label-for="blog-content"
                class="mb-2"
                id="accerdited-description"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Description"
                  rules=""
                >
                  <quill-editor
  
   ref="descriptionEditor"
                  v-model="AccerditedPageForm.description"
                  :options="getEditorOptions('descriptionEditor')"
                   
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              class=""
            >
              <div
                class="border rounded p-2"
                style="margin:0px"
              >
                <h4 class="mb-1">
                  header image
                </h4>
                <b-media
                  no-body
                  vertical-align="center"
                  class="flex-column flex-md-row"
                >
                  <b-media-aside>
                    <b-img
                      ref="refPreviewE2"
                      :src="Tabimage"
                      height="110"
                      width="170"
                      class="rounded mr-2 mb-1 mb-md-0"
                    />
                  </b-media-aside>
                  <b-media-body>
  
                    <b-card-text class="my-50" />
                    <div class="d-inline-block">
                      <b-form-group
                        label="header image"
                        label-for="blog-edit-title"
                        class="mb-2"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Tab Image "
                          rules="required"
                        >
                          <b-form-file
                          data-testid="accerdited-image"
                            ref="refInputE2"
                            v-model="AccerditedPageForm.image"
                            accept=".jpg, .png, .gif, .jpeg"
                            placeholder="Choose file"
                            @input="inputImageRendererTab"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <b-form-group
                        label="Image Caption"
                        label-for="blog-edit-title"
                        class="mb-2"
                      >
                        <b-form-input
                        data-testid="accerdited-caption"
                          v-model="AccerditedPageForm.image_caption"
                        />
                      </b-form-group>
                    </div>
                  </b-media-body>
                </b-media>
              </div>
  
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Title"
                label-for="blog-edit-title"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Course Name"
                  rules="required"
                >
                  <b-form-input
                  data-testid="accerdited-title"
                    v-model="AccerditedPageForm.title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
  
            <!-- <b-col md="4">
              <b-form-group
                label="Description"
                label-for="blog-edit-slug"
                class="mb-2"
              >
  
                 <validation-provider
                    #default="{ errors }"
                    name="description"
                    rules="required"
                  >
                <b-form-input
                  id="blog-edit-slug"
                  v-model="AccerditedPageForm.description"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->
  
           
            <b-col md="4">
              <b-form-group
                label=" Meta Title"
                label-for="blog-edit-title"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="meta_title"
                  rules="required"
                >
                  <b-form-input
                  data-testid="accerdited-meta-title"
                    v-model="AccerditedPageForm.meta_title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
  
            <b-col md="4">
              <b-form-group
                label="Meta Description"
                label-for="blog-edit-slug"
                class="mb-2"
              >
  
                <validation-provider
                  #default="{ errors }"
                  name="In-Link Name"
                  rules="required"
                >
                  <b-form-input
                  data-testid="accerdited-meta-description"
                    v-model="AccerditedPageForm.meta_description"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
  
            <b-col cols="4">
              <b-form-group
                label=" Meta Keywords"
                label-for="blog-content"
                class="mb-2"
              >
  
                <b-form-tags 
                data-testid="accerdited-meta-keyword"
                v-model="AccerditedPageForm.meta_keywords" />
  
              </b-form-group>
            </b-col>
  
            <b-col
              cols="12"
              class="mt-50"
            >
              <b-button
              data-testid="accerdited-button"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                @click="save"
              >
                Save Changes
              </b-button>
  
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
       <b-modal id="image-properties-modal" title="Image Properties">
      <b-form>
        <b-form-group label="Alt Text">
          <b-form-input v-model="imageProperties.alt"></b-form-input>
        </b-form-group>
        <b-form-group label="Width">
          <b-form-input v-model="imageProperties.width" placeholder="e.g., 100px or 50%"></b-form-input>
        </b-form-group>
        <b-form-group label="Height">
          <b-form-input v-model="imageProperties.height" placeholder="e.g., 100px or auto"></b-form-input>
        </b-form-group>
        <b-form-group label="Alignment">
          <b-form-select v-model="imageProperties.alignment">
            <option value="">None</option>
            <option value="ql-align-left">Left</option>
            <option value="ql-align-center">Center</option>
            <option value="ql-align-right">Right</option>
          </b-form-select>
        </b-form-group>
        <b-button variant="primary" @click="insertImage">Insert</b-button>
      </b-form>
    </b-modal>
      <!--/ form -->
    </b-card>
  </template>
  
  <script>
  import {
    BCard,
    BMedia,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BImg,
    BFormFile,
    BButton,
    BFormTags,
  } from 'bootstrap-vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { quillEditor } from 'vue-quill-editor'
  import { required, email } from '@validations'
  import Ripple from 'vue-ripple-directive'
  import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
  import { ref, reactive, getCurrentInstance } from '@vue/composition-api'
  import Vue from 'vue'
  import store from '@/store'
  // Import Quill and define custom size style
import Quill from 'quill'
const SizeStyle = Quill.import('attributors/style/size')
SizeStyle.whitelist = ['10px', '12px', '14px', '16px', '18px', '20px', '24px', '30px', '32px', '36px', '48px', '60px', '72px', '96px']
Quill.register(SizeStyle, true)

// Create a custom Image format
const BlockEmbed = Quill.import('blots/block/embed')
class ImageFormat extends BlockEmbed {
  static create(value) {
    let node = super.create(value)
    node.setAttribute('src', value.src)
    node.setAttribute('alt', value.alt || '')
    node.setAttribute('width', value.width || '')
    node.setAttribute('height', value.height || '')
    node.setAttribute('class', value.class || '')
    return node
  }

  static value(node) {
    return {
      src: node.getAttribute('src'),
      alt: node.getAttribute('alt'),
      width: node.getAttribute('width'),
      height: node.getAttribute('height'),
      class: node.getAttribute('class'),
    }
  }
}
ImageFormat.blotName = 'image'
ImageFormat.tagName = 'img'
Quill.register(ImageFormat, true)

  export default {
    setup() {
      // const store = useStore();
      const refInputEl = ref(null)
      const refInputE2 = ref(null)
      const refPreviewEl = ref(null)
      const refPreviewE2 = ref(null)
      const Tabimage = ref('media/svg/files/blank-image.svg')
  
      const form = ref({
        description: '',
        title: '',
        meta_title: '',
        meta_description: '',
        meta_keywords: '',
        keywords: '',
        image: '',
        image_caption: '',
  
      })
      const AccerditedPageForm = reactive(form)
      const { inputImageRenderer } = useInputImageRenderer(
        refInputEl,
        base64 => {
          refPreviewEl.value.src = base64
        },
      )
      const { inputImageRendererTab } = useInputImageRenderer(
        refInputE2,
        base64 => {
          refPreviewE2.value.src = base64
        },
      )
      const AccerditedPageFormvalidate = ref()
      store.dispatch('setting/getAccerditedPage').then(response => {
        form.value = response.page_content
  
        form.value.meta_keywords = response.page_content.meta_keywords.split(',')
      })
  
      const save = () => {
        const formData = new FormData()
  
        AccerditedPageFormvalidate.value.validate().then(success => {
          if (success) {
           
            formData.append('description', AccerditedPageForm.value.description)
            formData.append('meta_title', AccerditedPageForm.value.meta_title)
            formData.append('title', AccerditedPageForm.value.title)
            formData.append('meta_description', AccerditedPageForm.value.meta_description)
            formData.append('image', AccerditedPageForm.value.image)
            formData.append(' meta_keywords', AccerditedPageForm.value.meta_keywords)
            formData.append('image_caption', AccerditedPageForm.value.image_caption)
          
  
            store.dispatch('setting/addAccerditedPage', formData)
              .then(response => {
                Vue.swal({
                  title: ' Updated ',
                  icon: 'success',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              }).catch(error => {
                Vue.swal({
                  title: '',
                  text: `${error.response.data.message}`,
                  icon: 'error',
                  confirmButtonText: 'ok',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
          }
        })
      }
       const imageProperties = reactive({
      src: '',
      alt: '',
      width: '',
      height: '',
      alignment: '',
    })
    let editorSelection = null
    let currentEditor = null

    const imageHandler = editorRef => {
      currentEditor = proxy.$refs[editorRef].quill
      editorSelection = currentEditor.getSelection()
      const input = document.createElement('input')
      input.setAttribute('type', 'file')
      input.setAttribute('accept', 'image/*')
      input.click()

      input.onchange = async () => {
        const file = input.files[0]
        const formData = new FormData()
        formData.append('image', file)

        try {
          const token = localStorage.getItem('token')
          const xhr = new XMLHttpRequest()
          xhr.withCredentials = false
          xhr.open('POST', `${process.env.VUE_APP_ROOT_API}/upload_image_tiny_mce`)
          xhr.setRequestHeader('Authorization', `Bearer ${token}`)

          xhr.onload = function () {
            if (xhr.status === 403) {
              console.error(`HTTP Error: ${xhr.status}`)
              return
            }

            if (xhr.status < 200 || xhr.status >= 300) {
              console.error(`HTTP Error: ${xhr.status}`)
              return
            }

            const json = JSON.parse(xhr.responseText)

            if (!json || typeof json.location !== 'string') {
              console.error(`Invalid JSON: ${xhr.responseText}`)
              return
            }

            imageProperties.src = json.location
            proxy.$bvModal.show('image-properties-modal')
          }

          xhr.onerror = function () {
            console.error(`Image upload failed due to a XHR Transport error. Code: ${xhr.status}`)
          }

          xhr.send(formData)
        } catch (error) {
          console.error('Image upload failed:', error)
        }
      }
    }
 const { proxy } = getCurrentInstance()
    const insertImage = () => {
      const editor = currentEditor
    console.log(currentEditor.getSelection())
      const range = editorSelection
      if (!range) {
        console.error('No selection found')
        return
      }
      const { src, alt, width, height, alignment } = imageProperties
  
      editor.insertEmbed(range.index, 'image', { src, alt, width, height, class: alignment })
      proxy.$bvModal.hide('image-modal-post')
         imageProperties.src = '';
      imageProperties.alt = '';
      imageProperties.width = '';
      imageProperties.height = '';
      imageProperties.alignment = '';
    }


    const linkHandler = editorRef => {
      const range = proxy.$refs[editorRef].quill.getSelection()
      if (range) {
        const url = prompt('Enter the URL')
        if (url) {
          const openInNewTab = confirm('Open in new tab?')
          const selectedText = proxy.$refs[editorRef].quill.getText(range)
          const targetAttribute = openInNewTab ? ' target="_blank" rel="noopener noreferrer"' : ''
          const linkValue = `<a href="${url}"${targetAttribute}>${selectedText}</a>`

          // Remove the selected text
          proxy.$refs[editorRef].quill.deleteText(range.index, range.length)

          // Insert the link at the original selection index
          proxy.$refs[editorRef].quill.clipboard.dangerouslyPasteHTML(range.index, linkValue)
        }
      }
    }

    const getEditorOptions = editorRef => ({
      theme: 'snow',
      modules: {
        toolbar: {
          container: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: [] }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ direction: 'rtl' }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ['link', 'image', 'video'],
            [{ size: SizeStyle.whitelist }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
            ['clean'],
          ],
          handlers: {
            image: () => imageHandler(editorRef),
            link: () => linkHandler(editorRef),
          },
        },
      },
    })

      return {
        refInputEl,
        refPreviewEl,
        refInputE2,
        refPreviewE2,
        inputImageRendererTab,
        Tabimage,
        AccerditedPageForm,
        inputImageRenderer,
        AccerditedPageFormvalidate,
     getEditorOptions,
      imageHandler,
      insertImage,
      imageProperties,
        form,
  
        save,
        required,
        email,
      }
    },
  
    components: {
      BCard,
      BMedia,
      ValidationProvider,
      ValidationObserver,
      BCardText,
      BMediaAside,
      BMediaBody,
  
      BForm,
  
      BFormTags,
      BImg,
      BRow,
      BCol,
      BButton,
      BFormGroup,
      BFormInput,
      BFormFile,
     
      quillEditor,
    },
  
    directives: {
      Ripple,
    },
  }
  </script>
  
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  @import "@core/scss/vue/libs/quill.scss";
  @import "@core/scss/vue/pages/page-blog.scss";
  </style>
  